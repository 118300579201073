import React from "react";
import { AppProvider } from "./src/context";

import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
// Supports weights 100-900
import '@fontsource-variable/fraunces';
// Supports weights 400-700
import '@fontsource-variable/lora';

import "@styles/fonts.css";
import "@styles/global.css";
import "@styles/zoom.css";
import "@styles/screenshots.css";

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
